import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { useMutation, useQuery } from 'react-query';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import MutationMessage from 'components/Messages/MutationMessage';
import {useState } from 'react';
import {LoadingIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import {useNavigation, useSelect } from '@pankod/refine-core';
import {WebinarCategory } from 'interfaces/apiTypes';
import FieldRequiredErrorMessage from 'components/FieldRequiredErrorMessage';
import InputErrorMessage from 'components/InputErrorMessage';
import CategoryPill from 'components/CategoryPill';
import { CrossIcon } from 'components/icons';


export const CopyWebinarVideo: React.FC = () => {
  const [createdWebinarId, setCreatedWebinarId] = useState('');
  const [destinationWebinars, setDestinationWebinars] = useState<string[]>([]);
  const [statusMessage, setStatusMessage] = useState<{
    type: 'success' | 'error';
    message: string;
  } | null>(null);

  const { options } = useSelect({
    resource: 'webinars',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });
  const navigation = useNavigation();
  const {
    refineCore: { formLoading },
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      webinarId: '' // Set empty default value
    }
  });

  const formValues = watch();
  
  const cloneWorkshopMutation = useMutation(
    () => {
      const { webinarId } = formValues;  
      
      // Validation before mutation
      if (!webinarId) {
        throw new Error('Please select a source webinar');
      }
      if (destinationWebinars.length === 0) {
        throw new Error('Please select at least one destination webinar');
      }

      return axiosInstance.post(`/v1/webinars/copy-webinar-video`, {
        id: Number(webinarId),
        destinationWebinarIds: destinationWebinars.map(id => Number(id))
      });
    },
    {
      onSuccess: async (data) => {
        const { success, message, updates } = data.data;
        if (success) {
          setStatusMessage({
            type: 'success',
            message: `Success! ${message}. Updated webinars: ${updates.map(update => update.id).join(', ')}`
          });
        }
        // Clear form
        setValue('webinarId', '');
        setDestinationWebinars([]);
      },
      onError: async(error: any)=> {
        setStatusMessage({
          type: 'error',
          message: error.message || error.response?.data?.message || 'An error occurred while copying the video'
        });
      }
    },
  );

  
  return (
    <OuterContainer>
      <form autoComplete='off'
        onSubmit={handleSubmit(async (data) => {
          setStatusMessage(null);
          cloneWorkshopMutation.mutate();
        })}
      >
        <ItemsContainer>
          <div className="mt-8 space-y-4">
            <h1 className='my-heading'>Copy Webinar Video - </h1>
            
            {/* Source Webinar Selection */}
            <div>
              <label htmlFor="webinarId" className="my-label">
                Please Select Source Webinar
              </label>
              <select
                id="webinarId"
                className="my-input"
                {...register('webinarId', { 
                  required: 'Please select a source webinar'
                })}
                onChange={(e) => setValue('webinarId', e.target.value)}
              >
                <option value="">
                  Please select
                </option>
                {options?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label} (ID: {option.value})
                  </option>
                ))}
              </select>
              {errors.webinarId && (
                <InputErrorMessage>
                  {errors.webinarId.message}
                </InputErrorMessage>
              )}
            </div>

            <br />

            {/* Destination Webinars Multi-Select */}
            <div>
              <label htmlFor="destinationWebinars" className="my-label">
                Select Destination Webinars
              </label>
              
              <div className="flex gap-x-2 mb-2 flex-wrap">
                {destinationWebinars?.map((webinarId) => {
                  return (
                    <CategoryPill key={webinarId}>
                      <p>ID: {webinarId}</p>
                      <div
                        onClick={() =>
                          setDestinationWebinars((prev) => {
                            return prev.filter((id) => id !== webinarId);
                          })
                        }
                        className="w-[16px] cursor-pointer"
                      >
                        {CrossIcon}
                      </div>
                    </CategoryPill>
                  );
                })}
              </div>

              <select
                className="my-input mt-[12px]"
                value=""
                onChange={(e) => {
                  const newWebinarId = e.target.value;
                  if (!destinationWebinars.includes(newWebinarId) && 
                      newWebinarId !== formValues.webinarId) {
                    setDestinationWebinars([...destinationWebinars, newWebinarId]);
                  }
                }}
              >
                <option value="">
                  Please select destination webinars
                </option>
                {options?.map((option) => (
                  <option 
                    key={option.value} 
                    value={option.value}
                    disabled={option.value === formValues.webinarId}
                  >
                    {option.label} (ID: {option.value})
                  </option>
                ))}
              </select>
              {destinationWebinars.length === 0 && formValues.webinarId && (
                <InputErrorMessage>
                  Please select at least one destination webinar
                </InputErrorMessage>
              )}
            </div>
          </div>
                     
          <SaveButton 
            type="submit"
            disabled={!formValues.webinarId || destinationWebinars.length === 0}
          >
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>

          {statusMessage && (
            <div 
              className={`mt-4 p-4 rounded-md ${
                statusMessage.type === 'success' 
                  ? 'bg-green-100 text-green-700' 
                  : 'bg-red-100 text-red-700'
              }`}
            >
              {statusMessage.message}
            </div>
          )}
        </ItemsContainer>
      </form>
      <MutationMessage mutation={cloneWorkshopMutation} />
    </OuterContainer>
  );
};
